<template>
  <section class="pb-4">
    <ArbolDocumentosProceso :arbol="list_docs_var">
      <template v-slot:documentos="{ documentos }">
        <DataTableComponent
          class="my-4"
          :headers="HEADERS_CONST"
          :items="documentos"
          :tiene_paginacion="false"
          dense
          no-gutters
          item-key="id"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn icon @click="DownloadFileFtn(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
        </DataTableComponent>
      </template>
    </ArbolDocumentosProceso>
  </section>
</template>

<script>
import ArbolDocumentosProceso from '@/views/ProcesoCompraDoc/components/ArbolDocumentosProceso.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';

export default {
  components: { ArbolDocumentosProceso, DataTableComponent },
  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Documento",
        align: "center",
        value: "nombre_documento",
      },
      {
        text: "Acciones",
        value: "acciones",
        sortable: false,
        align: "center",
      },
    ],

    // variables
    list_docs_var: [],
  }),
  computed: {},
  methods: {

    async DownloadFileFtn(item) {
      try {
        const response = await this.services.PacProcesos.descargarDocumentoProceso({ ruta: item.ruta_documento });

        if (!response) {
          this.pushAppMessage({ type: "error", message: "Ocurrió un error al descargar el documento" });
  
          return;
        }
  
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const file = new File([blob], item.nombre_documento, { type: response.headers["content-type"] });
  
        const link = document.createElement("a");
        link.setAttribute("download", item.nombre_documento);
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      } catch (error) {
        this.pushAppMessage({ type: "error", message: "Ocurrió un error al descargar el documento" });
      }
    },

    async FetchListDocs() {

      const RESP_SC_CONST = await this.services.PacProcesos.getListDocuments(
        this.$route.params.idProceso
      );
      if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;
        this.list_docs_var = data;
      }

    },
  },
  created() {
    this.FetchListDocs();
  },
};
</script>

<style>
</style>